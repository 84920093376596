import logo from './assets/img/rays.png';
import logo2 from './assets/img/321-logo-outline.png';
import logo3 from './assets/img/321-rocket-text.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-container">
          <img src={logo} className="App-rays" alt="logo" />
          <img src={logo2} className="App-logo" alt="logo" />
          <img src={logo3} className="App-logo-text" alt="321 ROCKET" />
        </div>
      </header>
    </div>
  );
}

export default App;
